.view-news-events {
	.view-content-main {
		overflow: hidden;
	}
}

// If there's a sidebar, stack the news in Content additional between narrow and normal breakpoints
@media(min-width:$narrow) and (max-width:$normal) {
	.one-sidebar,
	.two-sidebar {
		.content-additional {
			.views-row:not(.side-row) {
				.view-news-events {
					.view-content-main {
						.views-row {
							width:100%;
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}

	
.views-row:not(.side-row) {
	.view-news-events {
		@include media($narrow) {
			.view-content-main {
				@include span-columns(8);
				overflow: hidden;

				.views-row {
					@include span-columns(4);
					overflow: hidden;
					&:nth-child(3n) { margin-right:0 !important; }
					&:nth-child(3n-2) { margin-left:0 !important; }
					&:nth-child(3n+1) { clear:left; }
				}
			}

			.attachment .view-content-main {
				@include span-columns(4);
				
				.card {
					height: auto !important;
				}

				.views-row {
					width:100%;
					margin-right: 0;
				}
			}
		}
	}
}
