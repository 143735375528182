$card-text-padding : $vert-space $horz-space !default;
$card-margin-bottom : $vert-space !default;

.card {
	box-sizing:border-box;
	display:block;
  margin-bottom:$card-margin-bottom;
	overflow:hidden;
	position:relative;
}
.card-text {
	box-sizing:border-box;
	display:block;
	overflow:hidden;
	padding:$card-text-padding;
	
	& > div:last-child {
		margin-bottom:0;
	}
}
.card-body {
	*:last-child {
		margin-bottom:0;
	}
}
.card-image,
.card-video,
.card-photo,
.card-logo {
	display:block;
	line-height:0;
}
.card-image,
.card-photo,
.card-logo {
  text-align:center;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}
 
.view-content {
	word-wrap: break-word;
}