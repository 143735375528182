table {
  table-layout:auto;
  width: auto;
	overflow-x: auto;
	display: block;
}

.page-user #messages {
  display: inline-block;
}
