
$tab-active-bg-color     : white !default;
$tab-active-text-color   : black !default;
$tab-active-border       : 1px solid #ccc !default;
$tab-inactive-bg-color   : #f2f2f2 !default;
$tab-inactive-text-color : #333 !default;
$tab-hover-bg-color      : #ddd !default;
$tab-hover-text-color    : black !default;
$tab-border              : 1px solid #ccc !default;
$tab-border-bottom       : 1px solid #ccc !default;

$horz-space              : 20px !default;
$vert-space              : 20px !default;



.tabs {
  &.primary {
    @include clearfix;
    line-height: 1.5;
    padding: 0 $horz-space/2;
    margin: $vert-space 0 0;
	
		
    li {
      list-style: none;
			width: 100%;

      @media (min-width:540px) {
        display: inline-block;
				width: auto;
      }

      a {
        background-color: $tab-inactive-bg-color;
        border-top: $tab-border;
        color: $tab-inactive-text-color;
        display: inline-block;
        font-weight: 400;
        padding: ($vert-space / 2) $gutter;
        text-decoration: none;

        @include media($narrow) {
          //@include inline-block;
					display:inline-block;
          border-top: 0;
        }

        &:hover {
          background: $tab-hover-bg-color;
          color: $tab-hover-text-color;
        }

        &:focus {
          outline: none;
        }

        &.active {
          border-bottom: 0;
					background: $tab-active-bg-color;
					color: $tab-active-text-color;

          @include media($narrow) {
            border: $tab-active-border;
            border-bottom-color: $tab-active-bg-color;
            margin-bottom: -1px;
          }
        }
      }

      .tab-content {
        display: none;
        padding: $vert-space $gutter;
        width: 100%;

        @include media($narrow) {
          border-top: $tab-border;
          float: left;
        }
      }
    }
  }
}
