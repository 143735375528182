#site-footer {
	@media (min-width:$narrow) {
		.view-footer-sections {
			> .view-content-main {
				display: flex;
				justify-content: space-between;
				
				> .views-row {
					flex-basis: 100%;
					margin-right: $gutt;
					
					&:last-child {margin-right: 0;}
				}
			}
		}
	}
  .copyright {
    @include span-columns(4);
  }

  .utility-menu {
    @include span-columns(8);
  }
	
	.item-list ul:not(.slick-slider) {
		list-style: none;
		margin: 0;
		page-break-inside: 0;
		
		li {
			margin: 0;
			padding: 0;
		}
	}
}