$body-margin : 0 !default;
$body-padding : 0 !default;


body {
  margin: $body-margin;
  padding: $body-padding;
}

.page {
	display: block;
	overflow: hidden;
}

.outer-wrapper {
  @include outer-container($site-max-width);
  width: 100%;
	box-sizing: border-box;
}

.equal-heights a {
	height: 100%;
	display:block;
}
.card-equal-heights {
	display: none;
}

img,
picture,
media,
video {
	height:auto !important;
  margin: 0;
  max-width: 100%;
  line-height:0;
}

.limited-text-width {
	.card-title,
	.card-body,
	.card-description-et {
		@media (min-width:880px) {
			padding:0 10% 0 0;
		}
		@media (min-width:$normal) {
			padding:0 12.5% 0 0;
		}
		@media (min-width:$wide) {
			padding:0 16% 0 0;
		}
	}
}

.item-list ul li {
    margin: 0;
    padding: 0;
}

.not-front {
	.postscript-first {
		clear:both;
		overflow:hidden;
	}
}