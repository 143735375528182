/* Mobile first layout SmartMenus Core CSS (it's not recommended editing these rules)
   You need this once per page no matter how many menu trees or different themes you use.
-------------------------------------------------------------------------------------------*/

.sm{position:relative;z-index:99;}
.sm,.sm ul,.sm li{display:block;list-style:none;margin:0;padding:0;line-height:normal;direction:ltr;text-align:left;-webkit-tap-highlight-color:rgba(0,0,0,0);}
.sm-rtl,.sm-rtl ul,.sm-rtl li{direction:rtl;text-align:right;}
.sm>li>h1,.sm>li>h2,.sm>li>h3,.sm>li>h4,.sm>li>h5,.sm>li>h6{margin:0;padding:0;}
.sm ul{display:none;}
.sm li,.sm a{position:relative;}
.sm a{display:block;}
.sm a.disabled{cursor:not-allowed;}
.sm:after{content:"\00a0";display:block;height:0;font:0px/0 serif;clear:both;visibility:hidden;overflow:hidden;}
.sm,.sm *,.sm *:before,.sm *:after{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box;}