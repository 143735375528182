#views-exposed-form-publications-all,
#views-exposed-form-resources-all,
#views-exposed-form-resources-publications-all {
  #edit-from-wrapper,
  #edit-to-wrapper {
    display: block;
  }
  #edit-year-wrapper {
    display: none !important;
  }
}