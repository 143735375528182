$section-main-padding : $vert-space*1.5 0 !default;

body {
  main {
    padding: $section-main-padding;
    display: flex;
    flex-wrap: wrap;
  }

  &.no-sidebars {
    main {
      section#content {
        width: 100%;
      }
      
    }
  }

  &.one-sidebar.sidebar-first {
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;}
      aside {
        &#sidebar-first {
          width: 100%;
          margin: calc(#{$gutt} / 2);
          
          @include media($narrow) {
            width: calc(100%/3 -  #{$gutt}/2);
          }
        }
      }

      section#content {
        width: 100%;
        margin: calc(#{$gutt} / 2);
        @include media($narrow) {
          width: calc(100%/3*2 -  #{$gutt}/2);
        }
      }
     } 
  }

  &.one-sidebar.sidebar-second {
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;} 
      aside {
        &#sidebar-second {
          width: 100%;
          margin: calc(#{$gutt} / 2);
          @include media($narrow) {
            width: calc(100%/3 -  #{$gutt});
          }
        }
      }

      section#content {
        width: 100%;
        margin: calc(100%-#{$gutt} / 2);
        @include media($narrow) {
          width: calc(100%/3*2 -  #{$gutt});
        }
      }
    }
  }

  &.two-sidebars {
    @media (min-width:narrow) {
      main {
        aside {
          &#sidebar-first {
            @include span-columns(3);
          }
  
          &#sidebar-second {
            @include span-columns(3);
          }
        }
  
        section#content {
          @include span-columns(6);
        }
      }
    }
  }
}